/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { LiveApiConfiguration as __Configuration } from '../live-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SystabcatSc } from '../models/systabcat-sc';
import { CategoryList } from '../models/category-list';
import { SystabSb } from '../models/systab-sb';
@Injectable({
  providedIn: 'root'
})
class MasterDataSourceService extends __BaseService {
  static readonly getDataSourcesAsyncPath = '/api/MasterDataSource/dataSourceDetails/{orid}';
  static readonly getDataSourcesAsyncOrgNamePath = '/api/MasterDataSource/{orid}';
  static readonly getDataSourcesByIdAsyncPath = '/api/MasterDataSource/dataSourcesById/{orid}/{scid}';
  static readonly addDataSourceAsyncPath = '/api/MasterDataSource';
  static readonly updateDataSourceAsyncPath = '/api/MasterDataSource';
  static readonly deleteDataSourceAsyncPath = '/api/MasterDataSource/{orid}/{scid}';
  static readonly getTypesAsyncPath = '/api/MasterDataSource/{orid}/{scid}';
  static readonly getAllTypesAsyncPath = '/api/MasterDataSource/all/{orid}';
  static readonly setDefaultDataTypePath = '/api/MasterDataSource/setDefault';
  static readonly addTypeAsyncPath = '/api/MasterDataSource/types';
  static readonly updateTypeAsyncPath = '/api/MasterDataSource/types';
  static readonly deleteTypeAsyncPath = '/api/MasterDataSource/deleteType/{orid}/{sbid}';
  static readonly getDataTypeByIdAsyncPath = '/api/MasterDataSource/dataTypesbyId/{orid}/{sbid}';
  static readonly getAllPageTextIdsAsyncPath = '/api/MasterDataSource/allPageText/{orid}';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getDataSourcesAsyncResponse(orid: number): __Observable<__StrictHttpResponse<Array<SystabcatSc>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterDataSource/dataSourceDetails/${encodeURIComponent(String(orid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SystabcatSc>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getDataSourcesAsync(orid: number): __Observable<Array<SystabcatSc>> {
    return this.getDataSourcesAsyncResponse(orid).pipe(__map((_r) => _r.body as Array<SystabcatSc>));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getDataSourcesAsyncOrgNameResponse(orid: number): __Observable<__StrictHttpResponse<Array<CategoryList>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/MasterDataSource/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CategoryList>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getDataSourcesAsyncOrgName(orid: number): __Observable<Array<CategoryList>> {
    return this.getDataSourcesAsyncOrgNameResponse(orid).pipe(__map((_r) => _r.body as Array<CategoryList>));
  }

  /**
   * @param params The `MasterDataSourceService.GetDataSourcesByIdAsyncParams` containing the following parameters:
   *
   * - `scid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  getDataSourcesByIdAsyncResponse(
    params: MasterDataSourceService.GetDataSourcesByIdAsyncParams
  ): __Observable<__StrictHttpResponse<SystabcatSc>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/MasterDataSource/dataSourcesById/${encodeURIComponent(String(params.orid))}/${encodeURIComponent(String(params.scid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SystabcatSc>;
      })
    );
  }
  /**
   * @param params The `MasterDataSourceService.GetDataSourcesByIdAsyncParams` containing the following parameters:
   *
   * - `scid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  getDataSourcesByIdAsync(params: MasterDataSourceService.GetDataSourcesByIdAsyncParams): __Observable<SystabcatSc> {
    return this.getDataSourcesByIdAsyncResponse(params).pipe(__map((_r) => _r.body as SystabcatSc));
  }

  /**
   * @param body undefined
   * @return Success
   */
  addDataSourceAsyncResponse(body?: SystabcatSc): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/MasterDataSource`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  addDataSourceAsync(body?: SystabcatSc): __Observable<number> {
    return this.addDataSourceAsyncResponse(body).pipe(__map((_r) => _r.body as number));
  }

  /**
   * @param body undefined
   * @return Success
   */
  updateDataSourceAsyncResponse(body?: SystabcatSc): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/MasterDataSource`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  updateDataSourceAsync(body?: SystabcatSc): __Observable<number> {
    return this.updateDataSourceAsyncResponse(body).pipe(__map((_r) => _r.body as number));
  }

  /**
   * @param params The `MasterDataSourceService.DeleteDataSourceAsyncParams` containing the following parameters:
   *
   * - `scid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  deleteDataSourceAsyncResponse(params: MasterDataSourceService.DeleteDataSourceAsyncParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/MasterDataSource/${encodeURIComponent(String(params.orid))}/${encodeURIComponent(String(params.scid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>;
      })
    );
  }
  /**
   * @param params The `MasterDataSourceService.DeleteDataSourceAsyncParams` containing the following parameters:
   *
   * - `scid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  deleteDataSourceAsync(params: MasterDataSourceService.DeleteDataSourceAsyncParams): __Observable<number> {
    return this.deleteDataSourceAsyncResponse(params).pipe(__map((_r) => _r.body as number));
  }

  /**
   * @param params The `MasterDataSourceService.GetTypesAsyncParams` containing the following parameters:
   *
   * - `scid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  getTypesAsyncResponse(params: MasterDataSourceService.GetTypesAsyncParams): __Observable<__StrictHttpResponse<Array<SystabSb>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MasterDataSource/${encodeURIComponent(String(params.orid))}/${encodeURIComponent(String(params.scid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SystabSb>>;
      })
    );
  }
  /**
   * @param params The `MasterDataSourceService.GetTypesAsyncParams` containing the following parameters:
   *
   * - `scid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  getTypesAsync(params: MasterDataSourceService.GetTypesAsyncParams): __Observable<Array<SystabSb>> {
    return this.getTypesAsyncResponse(params).pipe(__map((_r) => _r.body as Array<SystabSb>));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getAllTypesAsyncResponse(orid: number): __Observable<__StrictHttpResponse<Array<SystabSb>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/MasterDataSource/all/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SystabSb>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getAllTypesAsync(orid: number): __Observable<Array<SystabSb>> {
    return this.getAllTypesAsyncResponse(orid).pipe(__map((_r) => _r.body as Array<SystabSb>));
  }

  /**
   * @param body undefined
   * @return Success
   */
  setDefaultDataTypeResponse(body?: SystabSb): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/MasterDataSource/setDefault`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  setDefaultDataType(body?: SystabSb): __Observable<number> {
    return this.setDefaultDataTypeResponse(body).pipe(__map((_r) => _r.body as number));
  }

  /**
   * @param body undefined
   * @return Success
   */
  addTypeAsyncResponse(body?: SystabSb): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/MasterDataSource/types`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  addTypeAsync(body?: SystabSb): __Observable<number> {
    return this.addTypeAsyncResponse(body).pipe(__map((_r) => _r.body as number));
  }

  /**
   * @param body undefined
   * @return Success
   */
  updateTypeAsyncResponse(body?: SystabSb): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/MasterDataSource/types`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  updateTypeAsync(body?: SystabSb): __Observable<number> {
    return this.updateTypeAsyncResponse(body).pipe(__map((_r) => _r.body as number));
  }

  /**
   * @param params The `MasterDataSourceService.DeleteTypeAsyncParams` containing the following parameters:
   *
   * - `sbid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  deleteTypeAsyncResponse(params: MasterDataSourceService.DeleteTypeAsyncParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl +
        `/api/MasterDataSource/deleteType/${encodeURIComponent(String(params.orid))}/${encodeURIComponent(String(params.sbid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>;
      })
    );
  }
  /**
   * @param params The `MasterDataSourceService.DeleteTypeAsyncParams` containing the following parameters:
   *
   * - `sbid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  deleteTypeAsync(params: MasterDataSourceService.DeleteTypeAsyncParams): __Observable<number> {
    return this.deleteTypeAsyncResponse(params).pipe(__map((_r) => _r.body as number));
  }

  /**
   * @param params The `MasterDataSourceService.GetDataTypeByIdAsyncParams` containing the following parameters:
   *
   * - `sbid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  getDataTypeByIdAsyncResponse(params: MasterDataSourceService.GetDataTypeByIdAsyncParams): __Observable<__StrictHttpResponse<SystabSb>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/MasterDataSource/dataTypesbyId/${encodeURIComponent(String(params.orid))}/${encodeURIComponent(String(params.sbid))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SystabSb>;
      })
    );
  }
  /**
   * @param params The `MasterDataSourceService.GetDataTypeByIdAsyncParams` containing the following parameters:
   *
   * - `sbid`:
   *
   * - `orid`:
   *
   * @return Success
   */
  getDataTypeByIdAsync(params: MasterDataSourceService.GetDataTypeByIdAsyncParams): __Observable<SystabSb> {
    return this.getDataTypeByIdAsyncResponse(params).pipe(__map((_r) => _r.body as SystabSb));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getAllPageTextIdsAsyncResponse(orid: number): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/MasterDataSource/allPageText/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getAllPageTextIdsAsync(orid: number): __Observable<Array<string>> {
    return this.getAllPageTextIdsAsyncResponse(orid).pipe(__map((_r) => _r.body as Array<string>));
  }
}

module MasterDataSourceService {
  /**
   * Parameters for GetDataSourcesByIdAsync
   */
  export interface GetDataSourcesByIdAsyncParams {
    scid: number;
    orid: number;
  }

  /**
   * Parameters for DeleteDataSourceAsync
   */
  export interface DeleteDataSourceAsyncParams {
    scid: number;
    orid: number;
  }

  /**
   * Parameters for GetTypesAsync
   */
  export interface GetTypesAsyncParams {
    scid: number;
    orid: number;
  }

  /**
   * Parameters for DeleteTypeAsync
   */
  export interface DeleteTypeAsyncParams {
    sbid: number;
    orid: number;
  }

  /**
   * Parameters for GetDataTypeByIdAsync
   */
  export interface GetDataTypeByIdAsyncParams {
    sbid: number;
    orid: number;
  }
}

export { MasterDataSourceService };

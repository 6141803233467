/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { LiveApiConfiguration as __Configuration } from '../live-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FeatureList } from '../models/feature-list';
import { FeatureFe } from '../models/feature-fe';
@Injectable({
  providedIn: 'root'
})
class FeaturesService extends __BaseService {
  static readonly listOrganizationFeaturesPath = '/api/Features/org/{orid}';
  static readonly addDeviceFeaturePath = '/api/Features';
  static readonly updateDeviceFeaturePath = '/api/Features';
  static readonly deleteDeviceFeaturePath = '/api/Features';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param orid undefined
   * @return Success
   */
  listOrganizationFeaturesResponse(orid: number): __Observable<__StrictHttpResponse<Array<FeatureList>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Features/org/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<FeatureList>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  listOrganizationFeatures(orid: number): __Observable<Array<FeatureList>> {
    return this.listOrganizationFeaturesResponse(orid).pipe(__map((_r) => _r.body as Array<FeatureList>));
  }

  /**
   * @param params The `FeaturesService.AddDeviceFeatureParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  addDeviceFeatureResponse(params: FeaturesService.AddDeviceFeatureParams): __Observable<__StrictHttpResponse<FeatureFe>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.orid != null) __params = __params.set('orid', params.orid.toString());
    __body = params.body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/Features`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FeatureFe>;
      })
    );
  }
  /**
   * @param params The `FeaturesService.AddDeviceFeatureParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  addDeviceFeature(params: FeaturesService.AddDeviceFeatureParams): __Observable<FeatureFe> {
    return this.addDeviceFeatureResponse(params).pipe(__map((_r) => _r.body as FeatureFe));
  }

  /**
   * @param params The `FeaturesService.UpdateDeviceFeatureParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  updateDeviceFeatureResponse(params: FeaturesService.UpdateDeviceFeatureParams): __Observable<__StrictHttpResponse<FeatureFe>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.orid != null) __params = __params.set('orid', params.orid.toString());
    __body = params.body;
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/Features`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FeatureFe>;
      })
    );
  }
  /**
   * @param params The `FeaturesService.UpdateDeviceFeatureParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `body`:
   *
   * @return Success
   */
  updateDeviceFeature(params: FeaturesService.UpdateDeviceFeatureParams): __Observable<FeatureFe> {
    return this.updateDeviceFeatureResponse(params).pipe(__map((_r) => _r.body as FeatureFe));
  }

  /**
   * @param params The `FeaturesService.DeleteDeviceFeatureParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `feid`:
   *
   * @return Success
   */
  deleteDeviceFeatureResponse(params: FeaturesService.DeleteDeviceFeatureParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.orid != null) __params = __params.set('orid', params.orid.toString());
    if (params.feid != null) __params = __params.set('feid', params.feid.toString());
    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/api/Features`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>;
      })
    );
  }
  /**
   * @param params The `FeaturesService.DeleteDeviceFeatureParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `feid`:
   *
   * @return Success
   */
  deleteDeviceFeature(params: FeaturesService.DeleteDeviceFeatureParams): __Observable<number> {
    return this.deleteDeviceFeatureResponse(params).pipe(__map((_r) => _r.body as number));
  }
}

module FeaturesService {
  /**
   * Parameters for AddDeviceFeature
   */
  export interface AddDeviceFeatureParams {
    orid?: number;
    body?: FeatureFe;
  }

  /**
   * Parameters for UpdateDeviceFeature
   */
  export interface UpdateDeviceFeatureParams {
    orid?: number;
    body?: FeatureFe;
  }

  /**
   * Parameters for DeleteDeviceFeature
   */
  export interface DeleteDeviceFeatureParams {
    orid?: number;
    feid?: number;
  }
}

export { FeaturesService };

/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CoreApiConfiguration as __Configuration } from '../core-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeeRegistrationLoginEntry } from '../models/employee-registration-login-entry';
import { EmployeeRegistrationLogoutEntry } from '../models/employee-registration-logout-entry';
import { EmployeeRegistrationUpdateEntry } from '../models/employee-registration-update-entry';
import { ActiveEmployeeRegistration } from '../models/active-employee-registration';
import { ActiveEmployeeRegistrationData } from '../models/active-employee-registration-data';
import { EmployeeRegistrationLoginDetails } from '../models/employee-registration-login-details';
import { EmployeeRegistrationShift } from '../models/employee-registration-shift';
import { EmployeeRegistrationHistory } from '../models/employee-registration-history';
import { EmployeeInfo } from '../models/employee-info';
@Injectable({
  providedIn: 'root'
})
class EmployeeRegistrationService extends __BaseService {
  static readonly loginPath = '/api/v1/Login';
  static readonly logoutPath = '/api/v1/Logout';
  static readonly updateRegistrationPath = '/api/v1/UpdateRegistration';
  static readonly getActiveLoginPath = '/api/v1/{workCenterId}/GetActiveLogin';
  static readonly getActiveEmployeeRegistrationDataPath = '/api/v1/{workCenterId}/GetActiveEmployeeRegistrationData';
  static readonly getLoginDetailsPath = '/api/v1/{workCenterId}/GetLoginDetails/{employeeId}';
  static readonly getShiftsForFilterPath = '/api/v1/{workCenterId}/GetShiftsForFilter';
  static readonly getShiftsForFilterApprovalPath = '/api/v1/{workCenterId}/GetShiftsForFilter/approvals/{approvalReportDate}';
  static readonly getShiftsForFilterShiftReportPath = '/api/v1/{workCenterId}/GetShiftsForFilter/selectedShift/{shiftId}';
  static readonly getHistoryPath = '/api/v1/{workCenterId}/GetHistory';
  static readonly getEmployeesPath = '/api/v1/GetEmployees';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param entry undefined
   */
  loginResponse(entry: EmployeeRegistrationLoginEntry): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entry;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/v1/Login`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entry undefined
   */
  login(entry: EmployeeRegistrationLoginEntry): __Observable<null> {
    return this.loginResponse(entry).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param entry undefined
   */
  logoutResponse(entry: EmployeeRegistrationLogoutEntry): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entry;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/v1/Logout`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entry undefined
   */
  logout(entry: EmployeeRegistrationLogoutEntry): __Observable<null> {
    return this.logoutResponse(entry).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param entry undefined
   */
  updateRegistrationResponse(entry: EmployeeRegistrationUpdateEntry): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = entry;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/v1/UpdateRegistration`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param entry undefined
   */
  updateRegistration(entry: EmployeeRegistrationUpdateEntry): __Observable<null> {
    return this.updateRegistrationResponse(entry).pipe(__map((_r) => _r.body as null));
  }

  /**
   * @param workCenterId undefined
   */
  getActiveLoginResponse(workCenterId: number): __Observable<__StrictHttpResponse<ActiveEmployeeRegistration>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/${encodeURIComponent(String(workCenterId))}/GetActiveLogin`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ActiveEmployeeRegistration>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getActiveLogin(workCenterId: number): __Observable<ActiveEmployeeRegistration> {
    return this.getActiveLoginResponse(workCenterId).pipe(__map((_r) => _r.body as ActiveEmployeeRegistration));
  }

  /**
   * @param workCenterId undefined
   */
  getActiveEmployeeRegistrationDataResponse(workCenterId: number): __Observable<__StrictHttpResponse<ActiveEmployeeRegistrationData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/${encodeURIComponent(String(workCenterId))}/GetActiveEmployeeRegistrationData`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ActiveEmployeeRegistrationData>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getActiveEmployeeRegistrationData(workCenterId: number): __Observable<ActiveEmployeeRegistrationData> {
    return this.getActiveEmployeeRegistrationDataResponse(workCenterId).pipe(__map((_r) => _r.body as ActiveEmployeeRegistrationData));
  }

  /**
   * @param params The `EmployeeRegistrationService.GetLoginDetailsParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `employeeId`:
   */
  getLoginDetailsResponse(
    params: EmployeeRegistrationService.GetLoginDetailsParams
  ): __Observable<__StrictHttpResponse<EmployeeRegistrationLoginDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/v1/${encodeURIComponent(String(params.workCenterId))}/GetLoginDetails/${encodeURIComponent(String(params.employeeId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeRegistrationLoginDetails>;
      })
    );
  }
  /**
   * @param params The `EmployeeRegistrationService.GetLoginDetailsParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `employeeId`:
   */
  getLoginDetails(params: EmployeeRegistrationService.GetLoginDetailsParams): __Observable<EmployeeRegistrationLoginDetails> {
    return this.getLoginDetailsResponse(params).pipe(__map((_r) => _r.body as EmployeeRegistrationLoginDetails));
  }

  /**
   * @param workCenterId undefined
   */
  getShiftsForFilterResponse(workCenterId: number): __Observable<__StrictHttpResponse<Array<EmployeeRegistrationShift>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/${encodeURIComponent(String(workCenterId))}/GetShiftsForFilter`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeRegistrationShift>>;
      })
    );
  }
  /**
   * @param workCenterId undefined
   */
  getShiftsForFilter(workCenterId: number): __Observable<Array<EmployeeRegistrationShift>> {
    return this.getShiftsForFilterResponse(workCenterId).pipe(__map((_r) => _r.body as Array<EmployeeRegistrationShift>));
  }

  /**
   * @param params The `EmployeeRegistrationService.GetShiftsForFilterApprovalParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `approvalReportDate`:
   */
  getShiftsForFilterApprovalResponse(
    params: EmployeeRegistrationService.GetShiftsForFilterApprovalParams
  ): __Observable<__StrictHttpResponse<Array<EmployeeRegistrationShift>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/v1/${encodeURIComponent(String(params.workCenterId))}/GetShiftsForFilter/approvals/${encodeURIComponent(
          String(params.approvalReportDate)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeRegistrationShift>>;
      })
    );
  }
  /**
   * @param params The `EmployeeRegistrationService.GetShiftsForFilterApprovalParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `approvalReportDate`:
   */
  getShiftsForFilterApproval(
    params: EmployeeRegistrationService.GetShiftsForFilterApprovalParams
  ): __Observable<Array<EmployeeRegistrationShift>> {
    return this.getShiftsForFilterApprovalResponse(params).pipe(__map((_r) => _r.body as Array<EmployeeRegistrationShift>));
  }

  /**
   * @param params The `EmployeeRegistrationService.GetShiftsForFilterShiftReportParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `shiftId`:
   */
  getShiftsForFilterShiftReportResponse(
    params: EmployeeRegistrationService.GetShiftsForFilterShiftReportParams
  ): __Observable<__StrictHttpResponse<Array<EmployeeRegistrationShift>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/api/v1/${encodeURIComponent(String(params.workCenterId))}/GetShiftsForFilter/selectedShift/${encodeURIComponent(
          String(params.shiftId)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeRegistrationShift>>;
      })
    );
  }
  /**
   * @param params The `EmployeeRegistrationService.GetShiftsForFilterShiftReportParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `shiftId`:
   */
  getShiftsForFilterShiftReport(
    params: EmployeeRegistrationService.GetShiftsForFilterShiftReportParams
  ): __Observable<Array<EmployeeRegistrationShift>> {
    return this.getShiftsForFilterShiftReportResponse(params).pipe(__map((_r) => _r.body as Array<EmployeeRegistrationShift>));
  }

  /**
   * @param params The `EmployeeRegistrationService.GetHistoryParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `shiftIds`:
   */
  getHistoryResponse(
    params: EmployeeRegistrationService.GetHistoryParams
  ): __Observable<__StrictHttpResponse<Array<EmployeeRegistrationHistory>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    (params.shiftIds || []).forEach((val) => {
      if (val != null) __params = __params.append('shiftIds', val.toString());
    });
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/${encodeURIComponent(String(params.workCenterId))}/GetHistory`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeRegistrationHistory>>;
      })
    );
  }
  /**
   * @param params The `EmployeeRegistrationService.GetHistoryParams` containing the following parameters:
   *
   * - `workCenterId`:
   *
   * - `shiftIds`:
   */
  getHistory(params: EmployeeRegistrationService.GetHistoryParams): __Observable<Array<EmployeeRegistrationHistory>> {
    return this.getHistoryResponse(params).pipe(__map((_r) => _r.body as Array<EmployeeRegistrationHistory>));
  }
  getEmployeesResponse(): __Observable<__StrictHttpResponse<Array<EmployeeInfo>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/v1/GetEmployees`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeInfo>>;
      })
    );
  }
  getEmployees(): __Observable<Array<EmployeeInfo>> {
    return this.getEmployeesResponse().pipe(__map((_r) => _r.body as Array<EmployeeInfo>));
  }
}

module EmployeeRegistrationService {
  /**
   * Parameters for GetLoginDetails
   */
  export interface GetLoginDetailsParams {
    workCenterId: number;
    employeeId: number;
  }

  /**
   * Parameters for GetShiftsForFilterApproval
   */
  export interface GetShiftsForFilterApprovalParams {
    workCenterId: number;
    approvalReportDate: string;
  }

  /**
   * Parameters for GetShiftsForFilterShiftReport
   */
  export interface GetShiftsForFilterShiftReportParams {
    workCenterId: number;
    shiftId: number;
  }

  /**
   * Parameters for GetHistory
   */
  export interface GetHistoryParams {
    workCenterId: number;
    shiftIds?: null | Array<number>;
  }
}

export { EmployeeRegistrationService };

import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DecimalPipe } from '@angular/common';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { HttpClient } from '@angular/common/http';
import { Injectable, ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {
  CoreApiConfiguration,
  CoreApiConfigurationInterface,
  CoreApiModule,
  WebSocketClientConfiguration,
  WebSocketClientConfigurationInterface,
  WebSocketClientModule
} from 'chronos-core-client';
import { LiveApiConfiguration, LiveApiConfigurationInterface, LiveApiModule } from 'chronos-live-client';
import { PandaApiConfiguration, PandaApiConfigurationParams, PandaApiModule } from 'chronos-panda-client';
import { HighchartsChartModule } from 'highcharts-angular';
// import { NgpSortModule } from 'ngp-sort-pipe';
import { ChronosSharedConfiguration } from './chronos-shared-configuration';
import { ApplicationHeaderModule } from './components/application-header/application-header.module';
import { ContainerComponentsModule } from './components/container-components';
import { ContainerMountListModule } from './components/container-mount-list/container-mount-list.module';
import { DismountingModule } from './components/dismounting/dismounting.module';
import { TraceabilityModule } from './components/traceability/traceability.module';
import { FormComponentsModule } from './components/form-components/form-components.module';
import { MainMenuNavigationModule } from './components/main-menu-navigation/main-menu-navigation.module';

import { DemoComponent } from './demo/demo.component';
import { DirectivesModule } from './directives';
import { MachineChartComponent } from './machine-chart/machine-chart.component';
import { HighlighterDirective } from './machine-list/highlighter.directive';
import { MachineListComponent } from './machine-list/machine-list.component';
import { PipesModule } from './pipes/pipes.module';
import { DisplayInformationComponent } from './speed-monitor/components/display-information/display-information.component';
import { DowntimeModeComponent } from './speed-monitor/components/downtime-mode/downtime-mode.component';
import { IdleModeComponent } from './speed-monitor/components/idle-mode/idle-mode.component';
import { RunModeComponent } from './speed-monitor/components/run-mode/run-mode.component';
import { SetupModeComponent } from './speed-monitor/components/setup-mode/setup-mode.component';
import { KpiDisplayComponent } from './speed-monitor/components/kpi-display/kpi-display.component';
import { EllipsisPipe } from './speed-monitor/pipes/ellipsis.pipe';
import { MillisecondsToDurationPipe } from './speed-monitor/pipes/miliseconds-to-duration.pipe';
import { SpeedMonitorComponent } from './speed-monitor/speed-monitor.component';
import { ColumnSorterComponent } from './va-mat-table/actions/column-sorter/column-sorter.component';
import { VaMatTableComponent } from './va-mat-table/va-mat-table.component';
import { PhaseNavigationModule } from './components/phase-navigation/phase-navigation.module';
import { LanguageService, StorageService } from './services';
import { ContainerPalletModule } from './components/container-pallet/container-pallet.module';
import { CalendarModule } from 'primeng/calendar';
import { PanelModule } from 'primeng/panel';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { MultiSelectModule } from 'primeng/multiselect';
import { FileUploadModule } from 'primeng/fileupload';
import { ModalsModule } from './components/modals';
import { DismountingDummyPalletComponent } from './components/dismounting-dummy-pallet/dismounting-dummy-pallet.component';
import { FormatQuantityPipe } from './pipes';
import { BaseDataApiConfiguration, BaseDataApiConfigurationParams } from 'chronos-basedata-client';
import { ListboxModule } from 'primeng/listbox';

@Injectable()
export class SharedPandaApiConfiguration implements PandaApiConfigurationParams {
  constructor(private chronosSharedConfiguration: ChronosSharedConfiguration) {}

  public get rootUrl(): string {
    return this.chronosSharedConfiguration.pandaRootUrl;
  }
}

@Injectable()
export class SharedCoreApiConfiguration implements CoreApiConfigurationInterface {
  constructor(private chronosSharedConfiguration: ChronosSharedConfiguration) {}

  public get rootUrl(): string {
    return this.chronosSharedConfiguration.coreRootUrl;
  }
}

@Injectable()
export class SharedLiveApiConfiguration implements LiveApiConfigurationInterface {
  constructor(private chronosSharedConfiguration: ChronosSharedConfiguration) {}

  public get rootUrl(): string {
    return this.chronosSharedConfiguration.liveRootUrl;
  }
}

@Injectable()
export class SharedWebSocketClientConfiguration implements WebSocketClientConfigurationInterface {
  constructor(private chronosSharedConfiguration: ChronosSharedConfiguration) {}

  public get hubUrl(): string {
    return this.chronosSharedConfiguration.signalrBaseUrl;
  }
}

@Injectable()
export class SharedBaseDataApiConfiguration implements BaseDataApiConfigurationParams {
  constructor(private chronosSharedConfiguration: ChronosSharedConfiguration) {}

  public get rootUrl(): string {
    return this.chronosSharedConfiguration.basedataRootUrl;
  }
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    DemoComponent,
    SpeedMonitorComponent,
    KpiDisplayComponent,
    DisplayInformationComponent,
    DowntimeModeComponent,
    IdleModeComponent,
    RunModeComponent,
    SetupModeComponent,
    EllipsisPipe,
    MillisecondsToDurationPipe,
    MachineChartComponent,
    MachineListComponent,
    HighlighterDirective,
    VaMatTableComponent,
    ColumnSorterComponent,
    DismountingDummyPalletComponent
  ],
  imports: [
    CoreApiModule,
    PandaApiModule,
    LiveApiModule,
    WebSocketClientModule,
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    HighchartsChartModule,
    CalendarModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatSelectModule,
    MatTooltipModule,
    PanelModule,
    NgxDatatableModule,
    FlexLayoutModule,
    RouterModule,
    MainMenuNavigationModule,
    ApplicationHeaderModule,
    ContainerComponentsModule,
    FormComponentsModule,
    DismountingModule,
    TraceabilityModule,
    PipesModule,
    // NgpSortModule,
    MatCheckboxModule,
    MatMenuModule,
    MatTableModule,
    MatCardModule,
    DragDropModule,
    DropdownModule,
    InputNumberModule,
    DirectivesModule,
    ContainerMountListModule,
    PhaseNavigationModule,
    ContainerPalletModule,
    ModalsModule,
    ClipboardModule,
    CheckboxModule,
    MultiSelectModule,
    FileUploadModule,
    ListboxModule
  ],
  exports: [
    DemoComponent,
    SpeedMonitorComponent,
    DisplayInformationComponent,
    DowntimeModeComponent,
    IdleModeComponent,
    RunModeComponent,
    SetupModeComponent,
    EllipsisPipe,
    MillisecondsToDurationPipe,
    MachineListComponent,
    MachineChartComponent,
    MainMenuNavigationModule,
    ApplicationHeaderModule,
    ContainerComponentsModule,
    FormComponentsModule,
    DismountingModule,
    TraceabilityModule,
    PipesModule,
    DirectivesModule,
    VaMatTableComponent,
    ColumnSorterComponent,
    ContainerMountListModule,
    PhaseNavigationModule,
    ContainerPalletModule,
    ModalsModule,
    ClipboardModule,
    DismountingDummyPalletComponent
  ],
  providers: [
    ChronosSharedConfiguration,
    { provide: PandaApiConfiguration, useClass: SharedPandaApiConfiguration },
    { provide: CoreApiConfiguration, useClass: SharedCoreApiConfiguration },
    { provide: LiveApiConfiguration, useClass: SharedLiveApiConfiguration },
    {
      provide: WebSocketClientConfiguration,
      useClass: SharedWebSocketClientConfiguration
    },
    { provide: BaseDataApiConfiguration, useClass: SharedBaseDataApiConfiguration },
    StorageService,
    LanguageService,
    FormatQuantityPipe,
    DecimalPipe
  ]
})
export class ChronosSharedModule {
  public static forRoot(customParams: ChronosSharedConfiguration): ModuleWithProviders<ChronosSharedModule> {
    return {
      ngModule: ChronosSharedModule,
      providers: [
        {
          provide: ChronosSharedConfiguration,
          useValue: {
            coreRootUrl: customParams.coreRootUrl,
            pandaRootUrl: customParams.pandaRootUrl,
            liveRootUrl: customParams.liveRootUrl,
            signalrBaseUrl: customParams.signalrBaseUrl,
            basedataRootUrl: customParams.basedataRootUrl
          }
        }
      ]
    };
  }
}

/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { LiveApiConfiguration, LiveApiConfigurationInterface } from './live-api-configuration';

import { AclRoleService } from './services/acl-role.service';
import { AttributeGroupsService } from './services/attribute-groups.service';
import { AuthService } from './services/auth.service';
import { DashboardManagementService } from './services/dashboard-management.service';
import { FeaturesService } from './services/features.service';
import { FrontendConfigService } from './services/frontend-config.service';
import { GeneralSettingsService } from './services/general-settings.service';
import { GridSettingsService } from './services/grid-settings.service';
import { MasterDataSourceService } from './services/master-data-source.service';
import { MetaModelService } from './services/meta-model.service';
import { NavigationService } from './services/navigation.service';
import { OrganizationsService } from './services/organizations.service';
import { RoleService } from './services/role.service';
import { UserService } from './services/user.service';

/**
 * Provider for all LiveApi services, plus LiveApiConfiguration
 */
@NgModule({
  imports: [HttpClientModule],
  exports: [HttpClientModule],
  declarations: [],
  providers: [
    LiveApiConfiguration,
    AclRoleService,
    AttributeGroupsService,
    AuthService,
    DashboardManagementService,
    FeaturesService,
    FrontendConfigService,
    GeneralSettingsService,
    GridSettingsService,
    MasterDataSourceService,
    MetaModelService,
    NavigationService,
    OrganizationsService,
    RoleService,
    UserService
  ]
})
export class LiveApiModule {
  static forRoot(customParams: LiveApiConfigurationInterface): ModuleWithProviders<LiveApiModule> {
    return {
      ngModule: LiveApiModule,
      providers: [
        {
          provide: LiveApiConfiguration,
          useValue: { rootUrl: customParams.rootUrl }
        }
      ]
    };
  }
}

/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { LiveApiConfiguration as __Configuration } from '../live-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GridMasterGm } from '../models/grid-master-gm';
import { GridSettings } from '../models/grid-settings';
import { GridSettingsGs } from '../models/grid-settings-gs';
@Injectable({
  providedIn: 'root'
})
class GridSettingsService extends __BaseService {
  static readonly getGridMasterDetailsPath = '/api/GridSettings/getGridMasterDetails';
  static readonly getGridSettingsByUserPath = '/api/GridSettings/getGridSettingsByUser';
  static readonly getGridSettingTypesByUserPath = '/api/GridSettings/getGridSettingTypesByUser';
  static readonly isUserAdminRolePath = '/api/GridSettings/isUserAdminRole';
  static readonly isUserSettingPresentPath = '/api/GridSettings/isUserSettingPresent';
  static readonly saveGridSettingsPath = '/api/GridSettings/saveGridSettings';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @return Success
   */
  getGridMasterDetailsResponse(): __Observable<__StrictHttpResponse<Array<GridMasterGm>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/GridSettings/getGridMasterDetails`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<GridMasterGm>>;
      })
    );
  }
  /**
   * @return Success
   */
  getGridMasterDetails(): __Observable<Array<GridMasterGm>> {
    return this.getGridMasterDetailsResponse().pipe(__map((_r) => _r.body as Array<GridMasterGm>));
  }

  /**
   * @param params The `GridSettingsService.GetGridSettingsByUserParams` containing the following parameters:
   *
   * - `settingType`:
   *
   * - `gmname`:
   *
   * @return Success
   */
  getGridSettingsByUserResponse(params: GridSettingsService.GetGridSettingsByUserParams): __Observable<__StrictHttpResponse<GridSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.settingType != null) __params = __params.set('settingType', params.settingType.toString());
    if (params.gmname != null) __params = __params.set('gmname', params.gmname.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/GridSettings/getGridSettingsByUser`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GridSettings>;
      })
    );
  }
  /**
   * @param params The `GridSettingsService.GetGridSettingsByUserParams` containing the following parameters:
   *
   * - `settingType`:
   *
   * - `gmname`:
   *
   * @return Success
   */
  getGridSettingsByUser(params: GridSettingsService.GetGridSettingsByUserParams): __Observable<GridSettings> {
    return this.getGridSettingsByUserResponse(params).pipe(__map((_r) => _r.body as GridSettings));
  }

  /**
   * @param gmname undefined
   * @return Success
   */
  getGridSettingTypesByUserResponse(gmname?: string): __Observable<__StrictHttpResponse<Array<GridSettingsGs>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (gmname != null) __params = __params.set('gmname', gmname.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/GridSettings/getGridSettingTypesByUser`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<GridSettingsGs>>;
      })
    );
  }
  /**
   * @param gmname undefined
   * @return Success
   */
  getGridSettingTypesByUser(gmname?: string): __Observable<Array<GridSettingsGs>> {
    return this.getGridSettingTypesByUserResponse(gmname).pipe(__map((_r) => _r.body as Array<GridSettingsGs>));
  }

  /**
   * @param settingType undefined
   * @return Success
   */
  isUserAdminRoleResponse(settingType?: any): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (settingType != null) __params = __params.set('settingType', settingType.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/GridSettings/isUserAdminRole`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>;
      })
    );
  }
  /**
   * @param settingType undefined
   * @return Success
   */
  isUserAdminRole(settingType?: any): __Observable<boolean> {
    return this.isUserAdminRoleResponse(settingType).pipe(__map((_r) => _r.body as boolean));
  }

  /**
   * @param params The `GridSettingsService.IsUserSettingPresentParams` containing the following parameters:
   *
   * - `settingType`:
   *
   * - `gmname`:
   *
   * @return Success
   */
  isUserSettingPresentResponse(params: GridSettingsService.IsUserSettingPresentParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.settingType != null) __params = __params.set('settingType', params.settingType.toString());
    if (params.gmname != null) __params = __params.set('gmname', params.gmname.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/GridSettings/isUserSettingPresent`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>;
      })
    );
  }
  /**
   * @param params The `GridSettingsService.IsUserSettingPresentParams` containing the following parameters:
   *
   * - `settingType`:
   *
   * - `gmname`:
   *
   * @return Success
   */
  isUserSettingPresent(params: GridSettingsService.IsUserSettingPresentParams): __Observable<boolean> {
    return this.isUserSettingPresentResponse(params).pipe(__map((_r) => _r.body as boolean));
  }

  /**
   * @param params The `GridSettingsService.SaveGridSettingsParams` containing the following parameters:
   *
   * - `settingType`:
   *
   * - `gmname`:
   *
   * - `body`:
   *
   * @return Success
   */
  saveGridSettingsResponse(params: GridSettingsService.SaveGridSettingsParams): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.settingType != null) __params = __params.set('settingType', params.settingType.toString());
    if (params.gmname != null) __params = __params.set('gmname', params.gmname.toString());
    __body = params.body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/GridSettings/saveGridSettings`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>;
      })
    );
  }
  /**
   * @param params The `GridSettingsService.SaveGridSettingsParams` containing the following parameters:
   *
   * - `settingType`:
   *
   * - `gmname`:
   *
   * - `body`:
   *
   * @return Success
   */
  saveGridSettings(params: GridSettingsService.SaveGridSettingsParams): __Observable<number> {
    return this.saveGridSettingsResponse(params).pipe(__map((_r) => _r.body as number));
  }
}

module GridSettingsService {
  /**
   * Parameters for GetGridSettingsByUser
   */
  export interface GetGridSettingsByUserParams {
    settingType?: any;
    gmname?: string;
  }

  /**
   * Parameters for IsUserSettingPresent
   */
  export interface IsUserSettingPresentParams {
    settingType?: any;
    gmname?: string;
  }

  /**
   * Parameters for SaveGridSettings
   */
  export interface SaveGridSettingsParams {
    settingType?: any;
    gmname?: string;
    body?: GridSettings;
  }
}

export { GridSettingsService };

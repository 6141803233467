/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { LiveApiConfiguration as __Configuration } from '../live-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { OrganizationOr } from '../models/organization-or';
import { GeneralSettings } from '../models/general-settings';
@Injectable({
  providedIn: 'root'
})
class GeneralSettingsService extends __BaseService {
  static readonly getOrganizationsPath = '/api/GeneralSettings/getOrganizations';
  static readonly getOrganizationswithGeneralSettingsPath = '/api/GeneralSettings/getOrganizationswithGeneralSettings';
  static readonly getGeneralSettingsPath = '/api/GeneralSettings/getGeneralSettings';
  static readonly addorUpdateGeneralSettingsPath = '/api/GeneralSettings/addorUpdateGeneralSettings';
  static readonly deleteGeneralSettingsPath = '/api/GeneralSettings/deleteGeneralSettings';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getOrganizationsResponse(orid?: number): __Observable<__StrictHttpResponse<Array<OrganizationOr>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (orid != null) __params = __params.set('orid', orid.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/GeneralSettings/getOrganizations`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OrganizationOr>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getOrganizations(orid?: number): __Observable<Array<OrganizationOr>> {
    return this.getOrganizationsResponse(orid).pipe(__map((_r) => _r.body as Array<OrganizationOr>));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getOrganizationswithGeneralSettingsResponse(orid?: number): __Observable<__StrictHttpResponse<Array<OrganizationOr>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (orid != null) __params = __params.set('orid', orid.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/GeneralSettings/getOrganizationswithGeneralSettings`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<OrganizationOr>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getOrganizationswithGeneralSettings(orid?: number): __Observable<Array<OrganizationOr>> {
    return this.getOrganizationswithGeneralSettingsResponse(orid).pipe(__map((_r) => _r.body as Array<OrganizationOr>));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getGeneralSettingsResponse(orid?: number): __Observable<__StrictHttpResponse<OrganizationOr>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (orid != null) __params = __params.set('orid', orid.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/GeneralSettings/getGeneralSettings`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrganizationOr>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getGeneralSettings(orid?: number): __Observable<OrganizationOr> {
    return this.getGeneralSettingsResponse(orid).pipe(__map((_r) => _r.body as OrganizationOr));
  }

  /**
   * @param body undefined
   * @return Success
   */
  addorUpdateGeneralSettingsResponse(body?: GeneralSettings): __Observable<__StrictHttpResponse<OrganizationOr>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/GeneralSettings/addorUpdateGeneralSettings`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrganizationOr>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  addorUpdateGeneralSettings(body?: GeneralSettings): __Observable<OrganizationOr> {
    return this.addorUpdateGeneralSettingsResponse(body).pipe(__map((_r) => _r.body as OrganizationOr));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  deleteGeneralSettingsResponse(orid?: number): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (orid != null) __params = __params.set('orid', orid.toString());
    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/api/GeneralSettings/deleteGeneralSettings`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({
          body: parseFloat((_r as HttpResponse<any>).body as string)
        }) as __StrictHttpResponse<number>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  deleteGeneralSettings(orid?: number): __Observable<number> {
    return this.deleteGeneralSettingsResponse(orid).pipe(__map((_r) => _r.body as number));
  }
}

module GeneralSettingsService {}

export { GeneralSettingsService };

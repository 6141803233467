/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { LiveApiConfiguration as __Configuration } from '../live-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AclAccessRoleList } from '../models/acl-access-role-list';
import { AclAccessRequest } from '../models/acl-access-request';
import { AclroleAr } from '../models/aclrole-ar';
import { PageAccessDetails } from '../models/page-access-details';
import { UxpageUp } from '../models/uxpage-up';
import { AclfunctionAf } from '../models/aclfunction-af';
import { AcloperationAo } from '../models/acloperation-ao';
import { UxwidgetUw } from '../models/uxwidget-uw';
import { PageWidgetDetail } from '../models/page-widget-detail';
import { WidgetFunctionOperation } from '../models/widget-function-operation';
import { RoledataaccessRa } from '../models/roledataaccess-ra';
import { RoleDataAccessCustom } from '../models/role-data-access-custom';
import { AuthFunctionOperation } from '../models/auth-function-operation';
import { AuthWidgetOperation } from '../models/auth-widget-operation';
@Injectable({
  providedIn: 'root'
})
class AclRoleService extends __BaseService {
  static readonly getAclAccessRolesPath = '/api/AclRole/{orid}';
  static readonly getAclAccessDataForEditPath = '/api/AclRole/aclAccessDataForEdit';
  static readonly getAccessDataPath = '/api/AclRole/getAccessData';
  static readonly getAclRoleNamesByOrganizationPath = '/api/AclRole/aclRoleNames';
  static readonly getAccessOfComponentPath = '/api/AclRole/accessOfComponent';
  static readonly updateAclAccessRolePath = '/api/AclRole/updateAclAccessRole';
  static readonly addAccessPath = '/api/AclRole/add';
  static readonly updateAccessPath = '/api/AclRole/update';
  static readonly deleteAccessPath = '/api/AclRole/deleteAccess';
  static readonly checkAuthorizationPath = '/api/AclRole/checkAuthorization';
  static readonly getAllAclPagesNamesPath = '/api/AclRole/aclPageNames';
  static readonly getAllAclFunctionNamesByWidgetPath = '/api/AclRole/aclFunctionNamesByWidget';
  static readonly getAllAclOperationsPath = '/api/AclRole/aclOperations';
  static readonly getAllAclWidgetsPath = '/api/AclRole/aclWidgets';
  static readonly getAllAclPageWidgetsPath = '/api/AclRole/aclPageWidgets';
  static readonly getAllPageWidgetsOfRolePath = '/api/AclRole/aclPageWidgetsWithRole';
  static readonly getApiWidgetOperationsPath = '/api/AclRole/getApiWidgetOperations';
  static readonly getAclWidgetsPath = '/api/AclRole/allAclWidgets';
  static readonly getAllAclFunctionsPath = '/api/AclRole/aclFunctions';
  static readonly getWidgetFunctionAndEmptyOperationsPath = '/api/AclRole/widgetFunctionAndEmptyOperations';
  static readonly getWidgetFunctionAndOperationsWithValuePath = '/api/AclRole/widgetFunctionAndOperationsWithValue';
  static readonly getRoleDataAccessPath = '/api/AclRole/roleData/{arid}';
  static readonly getRoleDataAccessListPath = '/api/AclRole/roleData/list';
  static readonly addRoleDataAccessPath = '/api/AclRole/roleData/add';
  static readonly getAllAuthFunctionOperationPath = '/api/AclRole/role/list/authOperation';
  static readonly getAllAuthWidgetFunctionOperationPath = '/api/AclRole/role/list/authWidgetOperation';
  static readonly updateRoleDataAccessPath = '/api/AclRole/roleData/update';
  static readonly getRoleDataAccessByRolePath = '/api/AclRole/roleDataByArid/{arid}';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getAclAccessRolesResponse(orid: number): __Observable<__StrictHttpResponse<Array<AclAccessRoleList>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/AclRole/${encodeURIComponent(String(orid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AclAccessRoleList>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getAclAccessRoles(orid: number): __Observable<Array<AclAccessRoleList>> {
    return this.getAclAccessRolesResponse(orid).pipe(__map((_r) => _r.body as Array<AclAccessRoleList>));
  }

  /**
   * @param params The `AclRoleService.GetAclAccessDataForEditParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `arid`:
   *
   * @return Success
   */
  getAclAccessDataForEditResponse(
    params: AclRoleService.GetAclAccessDataForEditParams
  ): __Observable<__StrictHttpResponse<Array<AclAccessRoleList>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.orid != null) __params = __params.set('orid', params.orid.toString());
    if (params.arid != null) __params = __params.set('arid', params.arid.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/AclRole/aclAccessDataForEdit`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AclAccessRoleList>>;
      })
    );
  }
  /**
   * @param params The `AclRoleService.GetAclAccessDataForEditParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `arid`:
   *
   * @return Success
   */
  getAclAccessDataForEdit(params: AclRoleService.GetAclAccessDataForEditParams): __Observable<Array<AclAccessRoleList>> {
    return this.getAclAccessDataForEditResponse(params).pipe(__map((_r) => _r.body as Array<AclAccessRoleList>));
  }

  /**
   * @param params The `AclRoleService.GetAccessDataParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `arid`:
   *
   * @return Success
   */
  getAccessDataResponse(params: AclRoleService.GetAccessDataParams): __Observable<__StrictHttpResponse<AclAccessRequest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.orid != null) __params = __params.set('orid', params.orid.toString());
    if (params.arid != null) __params = __params.set('arid', params.arid.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/AclRole/getAccessData`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AclAccessRequest>;
      })
    );
  }
  /**
   * @param params The `AclRoleService.GetAccessDataParams` containing the following parameters:
   *
   * - `orid`:
   *
   * - `arid`:
   *
   * @return Success
   */
  getAccessData(params: AclRoleService.GetAccessDataParams): __Observable<AclAccessRequest> {
    return this.getAccessDataResponse(params).pipe(__map((_r) => _r.body as AclAccessRequest));
  }

  /**
   * @param orid undefined
   * @return Success
   */
  getAclRoleNamesByOrganizationResponse(orid?: number): __Observable<__StrictHttpResponse<Array<AclroleAr>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (orid != null) __params = __params.set('orid', orid.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/AclRole/aclRoleNames`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AclroleAr>>;
      })
    );
  }
  /**
   * @param orid undefined
   * @return Success
   */
  getAclRoleNamesByOrganization(orid?: number): __Observable<Array<AclroleAr>> {
    return this.getAclRoleNamesByOrganizationResponse(orid).pipe(__map((_r) => _r.body as Array<AclroleAr>));
  }

  /**
   * @param pageName undefined
   * @return Success
   */
  getAccessOfComponentResponse(pageName?: string): __Observable<__StrictHttpResponse<Array<PageAccessDetails>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (pageName != null) __params = __params.set('pageName', pageName.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/AclRole/accessOfComponent`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PageAccessDetails>>;
      })
    );
  }
  /**
   * @param pageName undefined
   * @return Success
   */
  getAccessOfComponent(pageName?: string): __Observable<Array<PageAccessDetails>> {
    return this.getAccessOfComponentResponse(pageName).pipe(__map((_r) => _r.body as Array<PageAccessDetails>));
  }

  /**
   * @param body undefined
   * @return Success
   */
  updateAclAccessRoleResponse(body?: AclAccessRoleList): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/api/AclRole/updateAclAccessRole`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  updateAclAccessRole(body?: AclAccessRoleList): __Observable<boolean> {
    return this.updateAclAccessRoleResponse(body).pipe(__map((_r) => _r.body as boolean));
  }

  /**
   * @param body undefined
   * @return Success
   */
  addAccessResponse(body?: AclAccessRequest): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/AclRole/add`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  addAccess(body?: AclAccessRequest): __Observable<boolean> {
    return this.addAccessResponse(body).pipe(__map((_r) => _r.body as boolean));
  }

  /**
   * @param body undefined
   * @return Success
   */
  updateAccessResponse(body?: AclAccessRequest): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/AclRole/update`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  updateAccess(body?: AclAccessRequest): __Observable<boolean> {
    return this.updateAccessResponse(body).pipe(__map((_r) => _r.body as boolean));
  }

  /**
   * @param arid undefined
   * @return Success
   */
  deleteAccessResponse(arid?: number): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (arid != null) __params = __params.set('arid', arid.toString());
    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/api/AclRole/deleteAccess`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>;
      })
    );
  }
  /**
   * @param arid undefined
   * @return Success
   */
  deleteAccess(arid?: number): __Observable<boolean> {
    return this.deleteAccessResponse(arid).pipe(__map((_r) => _r.body as boolean));
  }

  /**
   * @param afName undefined
   * @return Success
   */
  checkAuthorizationResponse(afName?: string): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (afName != null) __params = __params.set('afName', afName.toString());
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/AclRole/checkAuthorization`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>;
      })
    );
  }
  /**
   * @param afName undefined
   * @return Success
   */
  checkAuthorization(afName?: string): __Observable<boolean> {
    return this.checkAuthorizationResponse(afName).pipe(__map((_r) => _r.body as boolean));
  }

  /**
   * @param arid undefined
   * @return Success
   */
  getAllAclPagesNamesResponse(arid?: number): __Observable<__StrictHttpResponse<Array<UxpageUp>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (arid != null) __params = __params.set('arid', arid.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/AclRole/aclPageNames`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UxpageUp>>;
      })
    );
  }
  /**
   * @param arid undefined
   * @return Success
   */
  getAllAclPagesNames(arid?: number): __Observable<Array<UxpageUp>> {
    return this.getAllAclPagesNamesResponse(arid).pipe(__map((_r) => _r.body as Array<UxpageUp>));
  }

  /**
   * @param uwid undefined
   * @return Success
   */
  getAllAclFunctionNamesByWidgetResponse(uwid?: number): __Observable<__StrictHttpResponse<Array<AclfunctionAf>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (uwid != null) __params = __params.set('uwid', uwid.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/AclRole/aclFunctionNamesByWidget`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AclfunctionAf>>;
      })
    );
  }
  /**
   * @param uwid undefined
   * @return Success
   */
  getAllAclFunctionNamesByWidget(uwid?: number): __Observable<Array<AclfunctionAf>> {
    return this.getAllAclFunctionNamesByWidgetResponse(uwid).pipe(__map((_r) => _r.body as Array<AclfunctionAf>));
  }

  /**
   * @return Success
   */
  getAllAclOperationsResponse(): __Observable<__StrictHttpResponse<Array<AcloperationAo>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/AclRole/aclOperations`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AcloperationAo>>;
      })
    );
  }
  /**
   * @return Success
   */
  getAllAclOperations(): __Observable<Array<AcloperationAo>> {
    return this.getAllAclOperationsResponse().pipe(__map((_r) => _r.body as Array<AcloperationAo>));
  }

  /**
   * @param body undefined
   * @return Success
   */
  getAllAclWidgetsResponse(body?: Array<number>): __Observable<__StrictHttpResponse<Array<UxwidgetUw>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/AclRole/aclWidgets`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UxwidgetUw>>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  getAllAclWidgets(body?: Array<number>): __Observable<Array<UxwidgetUw>> {
    return this.getAllAclWidgetsResponse(body).pipe(__map((_r) => _r.body as Array<UxwidgetUw>));
  }

  /**
   * @param body undefined
   * @return Success
   */
  getAllAclPageWidgetsResponse(body?: Array<number>): __Observable<__StrictHttpResponse<Array<PageWidgetDetail>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/AclRole/aclPageWidgets`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PageWidgetDetail>>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  getAllAclPageWidgets(body?: Array<number>): __Observable<Array<PageWidgetDetail>> {
    return this.getAllAclPageWidgetsResponse(body).pipe(__map((_r) => _r.body as Array<PageWidgetDetail>));
  }

  /**
   * @param params The `AclRoleService.GetAllPageWidgetsOfRoleParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `arid`:
   *
   * @return Success
   */
  getAllPageWidgetsOfRoleResponse(
    params: AclRoleService.GetAllPageWidgetsOfRoleParams
  ): __Observable<__StrictHttpResponse<Array<PageWidgetDetail>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.arid != null) __params = __params.set('arid', params.arid.toString());
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/AclRole/aclPageWidgetsWithRole`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PageWidgetDetail>>;
      })
    );
  }
  /**
   * @param params The `AclRoleService.GetAllPageWidgetsOfRoleParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `arid`:
   *
   * @return Success
   */
  getAllPageWidgetsOfRole(params: AclRoleService.GetAllPageWidgetsOfRoleParams): __Observable<Array<PageWidgetDetail>> {
    return this.getAllPageWidgetsOfRoleResponse(params).pipe(__map((_r) => _r.body as Array<PageWidgetDetail>));
  }

  /**
   * @param arid undefined
   * @return Success
   */
  getApiWidgetOperationsResponse(arid?: number): __Observable<__StrictHttpResponse<PageWidgetDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (arid != null) __params = __params.set('arid', arid.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/AclRole/getApiWidgetOperations`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PageWidgetDetail>;
      })
    );
  }
  /**
   * @param arid undefined
   * @return Success
   */
  getApiWidgetOperations(arid?: number): __Observable<PageWidgetDetail> {
    return this.getApiWidgetOperationsResponse(arid).pipe(__map((_r) => _r.body as PageWidgetDetail));
  }

  /**
   * @return Success
   */
  getAclWidgetsResponse(): __Observable<__StrictHttpResponse<Array<UxwidgetUw>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/AclRole/allAclWidgets`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UxwidgetUw>>;
      })
    );
  }
  /**
   * @return Success
   */
  getAclWidgets(): __Observable<Array<UxwidgetUw>> {
    return this.getAclWidgetsResponse().pipe(__map((_r) => _r.body as Array<UxwidgetUw>));
  }

  /**
   * @return Success
   */
  getAllAclFunctionsResponse(): __Observable<__StrictHttpResponse<Array<AclfunctionAf>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/AclRole/aclFunctions`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AclfunctionAf>>;
      })
    );
  }
  /**
   * @return Success
   */
  getAllAclFunctions(): __Observable<Array<AclfunctionAf>> {
    return this.getAllAclFunctionsResponse().pipe(__map((_r) => _r.body as Array<AclfunctionAf>));
  }

  /**
   * @param uwid undefined
   * @return Success
   */
  getWidgetFunctionAndEmptyOperationsResponse(uwid?: number): __Observable<__StrictHttpResponse<Array<WidgetFunctionOperation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (uwid != null) __params = __params.set('uwid', uwid.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/AclRole/widgetFunctionAndEmptyOperations`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<WidgetFunctionOperation>>;
      })
    );
  }
  /**
   * @param uwid undefined
   * @return Success
   */
  getWidgetFunctionAndEmptyOperations(uwid?: number): __Observable<Array<WidgetFunctionOperation>> {
    return this.getWidgetFunctionAndEmptyOperationsResponse(uwid).pipe(__map((_r) => _r.body as Array<WidgetFunctionOperation>));
  }

  /**
   * @param params The `AclRoleService.GetWidgetFunctionAndOperationsWithValueParams` containing the following parameters:
   *
   * - `uwid`:
   *
   * - `arid`:
   *
   * @return Success
   */
  getWidgetFunctionAndOperationsWithValueResponse(
    params: AclRoleService.GetWidgetFunctionAndOperationsWithValueParams
  ): __Observable<__StrictHttpResponse<Array<WidgetFunctionOperation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.uwid != null) __params = __params.set('uwid', params.uwid.toString());
    if (params.arid != null) __params = __params.set('arid', params.arid.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/AclRole/widgetFunctionAndOperationsWithValue`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<WidgetFunctionOperation>>;
      })
    );
  }
  /**
   * @param params The `AclRoleService.GetWidgetFunctionAndOperationsWithValueParams` containing the following parameters:
   *
   * - `uwid`:
   *
   * - `arid`:
   *
   * @return Success
   */
  getWidgetFunctionAndOperationsWithValue(
    params: AclRoleService.GetWidgetFunctionAndOperationsWithValueParams
  ): __Observable<Array<WidgetFunctionOperation>> {
    return this.getWidgetFunctionAndOperationsWithValueResponse(params).pipe(__map((_r) => _r.body as Array<WidgetFunctionOperation>));
  }

  /**
   * @param arid undefined
   * @return Success
   */
  getRoleDataAccessResponse(arid: number): __Observable<__StrictHttpResponse<Array<RoledataaccessRa>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/AclRole/roleData/${encodeURIComponent(String(arid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RoledataaccessRa>>;
      })
    );
  }
  /**
   * @param arid undefined
   * @return Success
   */
  getRoleDataAccess(arid: number): __Observable<Array<RoledataaccessRa>> {
    return this.getRoleDataAccessResponse(arid).pipe(__map((_r) => _r.body as Array<RoledataaccessRa>));
  }

  /**
   * @return Success
   */
  getRoleDataAccessListResponse(): __Observable<__StrictHttpResponse<Array<RoleDataAccessCustom>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/AclRole/roleData/list`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RoleDataAccessCustom>>;
      })
    );
  }
  /**
   * @return Success
   */
  getRoleDataAccessList(): __Observable<Array<RoleDataAccessCustom>> {
    return this.getRoleDataAccessListResponse().pipe(__map((_r) => _r.body as Array<RoleDataAccessCustom>));
  }

  /**
   * @param body undefined
   * @return Success
   */
  addRoleDataAccessResponse(body?: Array<RoledataaccessRa>): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/AclRole/roleData/add`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  addRoleDataAccess(body?: Array<RoledataaccessRa>): __Observable<boolean> {
    return this.addRoleDataAccessResponse(body).pipe(__map((_r) => _r.body as boolean));
  }

  /**
   * @return Success
   */
  getAllAuthFunctionOperationResponse(): __Observable<__StrictHttpResponse<Array<AuthFunctionOperation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/AclRole/role/list/authOperation`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AuthFunctionOperation>>;
      })
    );
  }
  /**
   * @return Success
   */
  getAllAuthFunctionOperation(): __Observable<Array<AuthFunctionOperation>> {
    return this.getAllAuthFunctionOperationResponse().pipe(__map((_r) => _r.body as Array<AuthFunctionOperation>));
  }

  /**
   * @return Success
   */
  getAllAuthWidgetFunctionOperationResponse(): __Observable<__StrictHttpResponse<Array<AuthWidgetOperation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/AclRole/role/list/authWidgetOperation`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AuthWidgetOperation>>;
      })
    );
  }
  /**
   * @return Success
   */
  getAllAuthWidgetFunctionOperation(): __Observable<Array<AuthWidgetOperation>> {
    return this.getAllAuthWidgetFunctionOperationResponse().pipe(__map((_r) => _r.body as Array<AuthWidgetOperation>));
  }

  /**
   * @param body undefined
   * @return Success
   */
  updateRoleDataAccessResponse(body?: RoleDataAccessCustom): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/api/AclRole/roleData/update`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  updateRoleDataAccess(body?: RoleDataAccessCustom): __Observable<boolean> {
    return this.updateRoleDataAccessResponse(body).pipe(__map((_r) => _r.body as boolean));
  }

  /**
   * @param arid undefined
   * @return Success
   */
  getRoleDataAccessByRoleResponse(arid: number): __Observable<__StrictHttpResponse<RoleDataAccessCustom>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/AclRole/roleDataByArid/${encodeURIComponent(String(arid))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RoleDataAccessCustom>;
      })
    );
  }
  /**
   * @param arid undefined
   * @return Success
   */
  getRoleDataAccessByRole(arid: number): __Observable<RoleDataAccessCustom> {
    return this.getRoleDataAccessByRoleResponse(arid).pipe(__map((_r) => _r.body as RoleDataAccessCustom));
  }
}

module AclRoleService {
  /**
   * Parameters for GetAclAccessDataForEdit
   */
  export interface GetAclAccessDataForEditParams {
    orid?: number;
    arid?: number;
  }

  /**
   * Parameters for GetAccessData
   */
  export interface GetAccessDataParams {
    orid?: number;
    arid?: number;
  }

  /**
   * Parameters for GetAllPageWidgetsOfRole
   */
  export interface GetAllPageWidgetsOfRoleParams {
    body?: Array<number>;
    arid?: number;
  }

  /**
   * Parameters for GetWidgetFunctionAndOperationsWithValue
   */
  export interface GetWidgetFunctionAndOperationsWithValueParams {
    uwid?: number;
    arid?: number;
  }
}

export { AclRoleService };

/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { LiveApiConfiguration as __Configuration } from '../live-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UxpageUp } from '../models/uxpage-up';
@Injectable({
  providedIn: 'root'
})
class NavigationService extends __BaseService {
  static readonly getNavigationPath = '/api/Navigation/list';
  static readonly getChildNavigationPath = '/api/Navigation/list/child';
  static readonly validateNavigationPath = '/api/Navigation/validate';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @return Success
   */
  getNavigationResponse(): __Observable<__StrictHttpResponse<Array<UxpageUp>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Navigation/list`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UxpageUp>>;
      })
    );
  }
  /**
   * @return Success
   */
  getNavigation(): __Observable<Array<UxpageUp>> {
    return this.getNavigationResponse().pipe(__map((_r) => _r.body as Array<UxpageUp>));
  }

  /**
   * @param parentUrl undefined
   * @return Success
   */
  getChildNavigationResponse(parentUrl?: string): __Observable<__StrictHttpResponse<Array<UxpageUp>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (parentUrl != null) __params = __params.set('parentUrl', parentUrl.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Navigation/list/child`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UxpageUp>>;
      })
    );
  }
  /**
   * @param parentUrl undefined
   * @return Success
   */
  getChildNavigation(parentUrl?: string): __Observable<Array<UxpageUp>> {
    return this.getChildNavigationResponse(parentUrl).pipe(__map((_r) => _r.body as Array<UxpageUp>));
  }

  /**
   * @param navigationUrl undefined
   * @return Success
   */
  validateNavigationResponse(navigationUrl?: string): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (navigationUrl != null) __params = __params.set('navigationUrl', navigationUrl.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/api/Navigation/validate`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'text'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>;
      })
    );
  }
  /**
   * @param navigationUrl undefined
   * @return Success
   */
  validateNavigation(navigationUrl?: string): __Observable<boolean> {
    return this.validateNavigationResponse(navigationUrl).pipe(__map((_r) => _r.body as boolean));
  }
}

module NavigationService {}

export { NavigationService };
